import { Typography } from '@mui/material'

interface Props {
  text?: string
}

export function TitleInTab({ text }: Props) {
  if (!text) return null
  return (
    <Typography
      sx={{
        fontSize: { xs: '21px', sm: '24px' },
        fontWeight: 700,
        mt: { xs: '20px', sm: '32px' },
        mb: '40px',
      }}
    >
      {text}
    </Typography>
  )
}
