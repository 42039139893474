import { useNavigate } from 'react-router-dom'
import { Button, Container, Typography, Grid } from '@mui/material'
import { THEME_COLORS } from '../constants'
import landscape from '../assets/landscape-cream.svg'
import useWindowSize from '../lib/useWindowSize'
import settings from '../contents/settings.json'

const { white, darkGrey, lightBlue, coconutMilk } = THEME_COLORS

export function HomepageAR() {
  const navigate = useNavigate()
  const { width } = useWindowSize()

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: '100%',
        minHeight: '100vh',
        backgroundColor: darkGrey,
        paddingY: 4,
      }}
    >
      <Typography color={coconutMilk} sx={{ paddingX: 2, fontSize: 16, fontWeight: 400 }}>
        Gates Discovery Center
      </Typography>
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundImage: `url(${landscape})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          height: width * 0.533,
          position: 'relative',
          borderBottom: `1px solid ${coconutMilk}`,
          marginTop: 5,
        }}
      >
        <Typography
          sx={{
            backgroundColor: 'rgba(64, 62, 65,0)',
            position: 'absolute',
            bottom: 38,
            left: 16,
            right: 110,
            fontFamily: ['Noto Serif', 'Arial'].join(','),
            color: lightBlue,
            fontSize: 30,
            fontWeight: 900,
            lineHeight: '41px',
            letterSpacing: '-0.02em',
          }}
        >
          {settings.arTitle}
        </Typography>
      </Container>

      <Container disableGutters sx={{ padding: 2, marginTop: 0.5, marginBottom: 3 }}>
        <Typography
          variant="h2"
          color={coconutMilk}
          sx={{ fontSize: 14, fontWeight: 600, marginBottom: '36px' }}
        >
          {settings.arSubtitle}
        </Typography>

        {settings.arText.map((paragraph, i) => (
          <Typography key={i} color={coconutMilk} sx={{ fontSize: 16, fontWeight: 500 }}>
            {paragraph}
          </Typography>
        ))}
      </Container>

      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          onClick={() => navigate(`/wdwgfh/ar-experience`)}
          sx={{
            backgroundColor: coconutMilk,
            color: '#4D5B6A',
            paddingY: 2,
            borderRadius: 2,
            textTransform: 'none',
            fontSize: 21,
            fontWeight: 900,
          }}
        >
          Start the Journey!
        </Button>
      </Grid>
    </Container>
  )
}
