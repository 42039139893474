import { Container } from '@mui/material'
import { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Picture } from '../peopleContent'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import '../gallerySwiperStyle.css'

interface GalleryProps {
  images: Picture[]
}

export function Gallery({ images }: GalleryProps) {
  return (
    <Container disableGutters sx={{ height: 450, mt: 6, mb: { xs: '16px', sm: '0px' } }}>
      <Swiper
        speed={500}
        slidesPerView={1}
        spaceBetween={15}
        pagination={{
          el: '.custom-pagination-div',
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"></span>'
          },
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
      >
        {images.map((imageSrc, i) => {
          return (
            <SwiperSlide key={i}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img src={imageSrc.url} alt={imageSrc.alt} style={{ maxHeight: 400 }} />
                <p style={{ fontSize: 13 }}>{imageSrc.alt}</p>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className="custom-pagination-div" />
    </Container>
  )
}
