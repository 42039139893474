import { Typography } from '@mui/material'

interface Props {
  paragraphs?: string[]
}

export function Transcript({ paragraphs = [] }: Props) {
  if (!paragraphs) return null

  return (
    <>
      <Typography variant="h6">Transcript</Typography>
      {paragraphs.map((t, i) => (
        <Typography key={i} sx={{ marginTop: 1.5 }}>
          {t}
        </Typography>
      ))}
    </>
  )
}
