import { createTheme } from '@mui/material/styles'
// import type {} from '@mui/lab/themeAugmentation'
import '@fontsource/noto-sans'
import '@fontsource/noto-sans/600.css'
import '@fontsource/noto-sans/700.css'
import '@fontsource/noto-sans/900.css'
import '@fontsource/noto-serif'
import '@fontsource/noto-serif/700.css'
import { THEME_COLORS } from './constants'


declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    landing: true
  }
}

const { coconutMilk, darkGrey, lightGray, lightBlue, orange, white } = THEME_COLORS

const palette = {
  primary: { main: darkGrey, light: lightGray },
  secondary: { main: coconutMilk },
  common: { white: white },
}

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: ['Noto Sans', 'Arial', 'sans-serif'].join(','),
      fontSize: 16,
      color: darkGrey,
    },
    body2: {
      fontSize: 24,
    },
    h1: {
      fontFamily: ['Noto Serif', 'Arial'].join(','),
      color: lightBlue,
      fontSize: 21,
      fontWeight: 900,
    },
    h2: {
      fontSize: 14,
      fontWeight: 600,
    },
    h3: {
      fontSize: 12,
      fontWeight: 700,
    },
    h4: {
      fontSize: 34,
      fontWeight: 900,
    },
    h5: {
      fontSize: 24,
      fontWeight: 700,
      color: darkGrey,
    },
    h6: {
      fontSize: 16,
      fontWeight: 700,
      color: darkGrey,
    },
  },
  palette,
  breakpoints: {
    values: {
      xs: 353,
      sm: 540,
      landing: 650,
      md: 1110,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: coconutMilk,
          boxShadow: 'none',
          borderBottom: `1px solid ${darkGrey}`,
          color: darkGrey,
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0,
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: coconutMilk,
          borderTop: `1px solid ${darkGrey}`,
          color: darkGrey,
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0,
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: { padding: 0 },
        thumb: {
          backgroundColor: darkGrey,
          boxShadow: 'none',
          width: 0,
          height: 0,
          '&:hover, &.Mui-active': {
            display: 'none',
          },
        },
        track: {
          backgroundColor: darkGrey,
          border: 'none',
          height: 4,
        },
        rail: {
          height: 4,
          backgroundColor: lightGray,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: { border: `1px solid ${darkGrey}` },
        root: {
          paddingTop: 32,
          paddingBottom: 32,
        },
        list: {
          paddingTop: 32,
          paddingBottom: 32,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: lightBlue,
          fontSize: '12px',
          lineHeight: '32px',
          fontWeight: 700,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '32px',
          paddingLeft: '32px',
          paddingRight: '32px',
          paddingTop:'4px',
          paddingBottom:'4px',
          '&:hover': {
            color: orange,
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none',
        },
        flexContainer: theme => ( {
          justifyContent: 'space-between',
          
          button: {
            padding: 0,
            fontSize: 16,
            fontWeight: 600,
            minWidth:'min-content',
            [theme.theme.breakpoints.down("sm")]: {fontSize:12},
            textTransform: 'capitalize',
            
          },
        }),
        scroller: {
          background: coconutMilk,
          minHeight: 'min-content',
          display:"flex",
          justifyContent: 'center',
        },
        root: {
          margin: '0 !important',
          minHeight: '48px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          opacity: 0.4,
          '&.Mui-selected': {
            opacity: 1,
            textDecoration: 'underline',
          },
          '&:hover': {
            opacity: 1,
            borderRadius: 8,
          },
          '&.MuiButtonBase-root': {
            minHeight: 'min-content',
            paddingBottom: 16,
            paddingTop: 16,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: coconutMilk,
          boxShadow: 'none',
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          width: 258,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#e5e2d7',
          },
        },
      },
    },
  },
})

export default theme
