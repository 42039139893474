import { Container, Box, Typography, Link } from '@mui/material'
import { THEME_COLORS } from '../constants'
import logo from '../assets/logo.svg'

const { darkGrey } = THEME_COLORS

export function Footer() {
  return (
    <Container
      disableGutters
      sx={{
        borderTop: `1px solid ${darkGrey}`,
        px: 2,
        mt: 12,
      }}
    >
      <Box
        sx={{
          paddingY: 2,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          rowGap: '10px',
        }}
      >
        <img src={logo} alt="Gates Foundation logo" style={{ maxWidth: 168, maxHeight: 44 }} />
        <Box>
          <Typography sx={{ fontSize: 12 }}>
            {'© 2022 Bill & Melinda Gates Foundation. All rights reserved.'}
          </Typography>
          <Link
            href="https://www.gatesfoundation.org/privacy-and-cookies-notice"
            underline="none"
            sx={{ fontSize: 12, color: '#0e6691' }}
          >
            {'Privacy & Cookies Notice'}
          </Link>
        </Box>
      </Box>
    </Container>
  )
}
