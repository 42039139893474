import { AppBar, Box, Grid, Toolbar, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { NavigationMenu } from './NavigationMenu'
import settings from '../contents/settings.json'

export function Header() {
  const navigate = useNavigate()

  return (
    <AppBar position="sticky">
      <Toolbar
        disableGutters
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          pt: { xs: '19px', sm: '27px' },
        }}
      >
        <Grid container direction="row" justifyContent="left" alignItems="center">
          <Box
            onClick={() => navigate('/wdwgfh/')}
            sx={{
              cursor: 'pointer',
              px: 2,
              pb: { xs: '18px', sm: '24px' },
            }}
          >
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: '14px', sm: '21px' }, pb: '4px', pt: '6px' }}
            >
              {settings.landingTitle}
            </Typography>
            <Typography variant="h2" sx={{ fontSize: { xs: '10px', sm: '14px' } }}>
              {settings.landingSubtitle}
            </Typography>
          </Box>
        </Grid>

        <NavigationMenu />
      </Toolbar>
    </AppBar>
  )
}
