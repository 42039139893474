import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { Slider, Box, Grid } from '@mui/material'
import Replay10 from '@mui/icons-material/Replay10'
import SkipNext from '@mui/icons-material/SkipNext'
import PlayArrow from '@mui/icons-material/PlayArrow'
import Pause from '@mui/icons-material/Pause'
import { THEME_COLORS } from '../constants'
import { Track } from '../peopleContent'
import AudioPlayerButton from './AudioPlayerButton'
import { formatSeconds } from '../lib/utils'
import { AudioReactiveGlobe } from './AudioReactiveGlobe.js'

const INPUT_MAX_VALUE = 100
const SKIP_NEXT = false
const { coconutMilk, darkGrey } = THEME_COLORS

interface Props {
  tracks: Track[]
}

function useForceFirstRender() {
  const [, setState] = useState(false)
  useEffect(() => {
    setState(true)
  }, [])
}

export function AudioPlayer({ tracks }: Props) {
  const audioPlayer = useRef<HTMLAudioElement>(null)
  useForceFirstRender() //to use audio player ref inside render

  const [isLoading, setIsLoading] = useState(true)
  const [playerError, setPlayerError] = useState<SyntheticEvent<HTMLAudioElement, Event> | null>(
    null
  )
  const [trackIndex, setTrackIndex] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [seekValue, setSeekValue] = useState(0)
  const currentTrack = tracks[trackIndex]
  const remainingTime = duration - currentTime
  const isLastAudio = trackIndex === tracks.length - 1

  useEffect(() => {
    setIsLoading(true)
  }, [trackIndex])

  function play() {
    setIsPlaying(true)
    audioPlayer.current?.play()
  }

  function pause() {
    setIsPlaying(false)
    audioPlayer.current?.pause()
  }

  function replayTen() {
    pause()
    if (!audioPlayer.current) {
      return
    }
    audioPlayer.current.currentTime = audioPlayer.current?.currentTime - 10
    play()
  }

  function next() {
    pause()
    if (!audioPlayer.current) {
      return
    }
    audioPlayer.current.currentTime = 0
    if (isLastAudio) {
      setTrackIndex(0)
      pause()
    } else {
      setTrackIndex(trackIndex + 1)
      play()
    }
  }

  function onPlaying() {
    const currentTime = audioPlayer.current?.currentTime
    if (!currentTime) {
      return
    }
    setCurrentTime(currentTime)
    setSeekValue((currentTime / duration) * INPUT_MAX_VALUE)
    const isAudioFinished = currentTime >= duration
    if (isAudioFinished) {
      next()
    }
  }
  // function log(...a: any[]) {
  //   a.forEach((b) => (document.querySelector('#logs').innerHTML += String(b) + '<br/>'))
  // }

  return (
    <Box id="audio-player-container" style={{ width: '100%', background: coconutMilk }}>
      <div style={{ position: 'relative', width: '100%' }}>
        {audioPlayer.current && <AudioReactiveGlobe audioFile={audioPlayer.current} />}
        {isLoading === true && (
          <Box
            color={darkGrey}
            sx={{
              top: '50%',
              width: '100%',
              position: 'absolute',
              textAlign: 'center',
              transform: 'translate(0,-50%)',
              fontSize: '20px',
              opacity: 0.5,
            }}
          >
            LOADING...
          </Box>
        )}
      </div>
      <div>
        <audio
          src={currentTrack.src.replace(
            'https://discovery-center-ar.cdn.prismic.io/discovery-center-ar/',
            '/prismic-assets/'
          )}
          ref={audioPlayer}
          preload="auto"
          onLoadedMetadata={(event: SyntheticEvent<HTMLAudioElement>) => {
            const durationSec = event.currentTarget.duration
            setDuration(durationSec)
            setIsLoading(false)
          }}
          onTimeUpdate={onPlaying}
          onError={(err) => {
            err.persist()
            setPlayerError(err)
          }}
          autoPlay={false}
        >
          Your browser does not support the <code>audio</code> element.
        </audio>
        <>
          {playerError && (
            <Box color={'red'}>ERROR: {(playerError.target as any)?.error?.message}</Box>
          )}
          <>
            <Grid container>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box color={darkGrey}>{formatSeconds(currentTime)}</Box>
                <Box color={darkGrey}>{`-${formatSeconds(remainingTime)}`}</Box>
              </Grid>
            </Grid>
            <Slider
              aria-label="seek"
              value={seekValue}
              onChange={(event: Event, value: number | Array<number>, activeThumb: number) => {
                // @ts-ignore
                const currentValue = Number(event.target?.value)
                const seekTo = duration * (currentValue / INPUT_MAX_VALUE)
                if (!audioPlayer.current) {
                  return
                }
                audioPlayer.current.currentTime = seekTo
                setSeekValue(currentValue)
              }}
            />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box color={darkGrey}>{currentTrack.title}</Box>
              </Grid>
              <Grid item>
                <AudioPlayerButton
                  onClick={() => replayTen()}
                  ariaLabel="replay-10"
                  icon={<Replay10 />}
                  disabled={isLoading}
                />
                {SKIP_NEXT && (
                  <AudioPlayerButton
                    onClick={() => next()}
                    ariaLabel="skip-next"
                    icon={<SkipNext />}
                  />
                )}

                {isPlaying ? (
                  <AudioPlayerButton
                    onClick={() => pause()}
                    ariaLabel="pause"
                    icon={<Pause />}
                    disabled={isLoading}
                  />
                ) : (
                  <AudioPlayerButton
                    onClick={() => {
                      play()

                      // Safari fix because AudioContext can't be created in a 'play' handler (see AudioReactiveGlobe)
                      audioPlayer.current?.click()
                    }}
                    ariaLabel="play"
                    icon={<PlayArrow />}
                    disabled={isLoading}
                  />
                )}
              </Grid>
            </Grid>
          </>
        </>
        {/* <div id="logs"></div> */}
      </div>
    </Box>
  )
}
