import { useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import ReactPlayer from 'react-player'
import { VideoContent } from '../peopleContent'
import { TitleInTab } from './TitleInTab'
import { Transcript } from './Transcript'

interface Props {
  videoTabData: VideoContent
}

export function VideoTab({ videoTabData }: Props) {
  const [isThereAnError, setIsThereAnError] = useState(false)

  return (
    <Box sx={{ pt: '70px' }}>
      {videoTabData.map(({ title, src, transcript }, i) => {
        return (
          <Box key={i} marginBottom={3}>
            {ReactPlayer.canPlay(src) && !isThereAnError ? (
              <div
                style={{
                  position: 'relative',
                  paddingTop: '56.25%',
                }}
              >
                <div
                  style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                >
                  <ReactPlayer
                    url={src}
                    width="100%"
                    height="100%"
                    style={{ top: 0, left: 0 }}
                    loop={false}
                    controls={true}
                    stopOnUnmount={true}
                    onError={() => {
                      setIsThereAnError(true)
                      console.warn('Something goes wrong loading the video...')
                    }}
                  />
                </div>
              </div>
            ) : (
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Button
                  variant="outlined"
                  disableElevation
                  onClick={() => window.open(src, '_blank')}
                  sx={{
                    backgroundColor: 'secondary.main',
                    color: 'primary.main',
                    paddingY: 2,
                    borderRadius: 2,
                    textTransform: 'none',
                    fontSize: 21,
                    fontWeight: 700,
                    border: `1px solid`,
                    ':hover': {
                      backgroundColor: 'primary.main',
                      color: 'secondary.main',
                    },
                  }}
                >
                  Click here to watch the video
                </Button>
              </Grid>
            )}

            <TitleInTab text={title} />

            <Transcript paragraphs={transcript} />
          </Box>
        )
      })}
    </Box>
  )
}
