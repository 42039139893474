import { useState, useMemo, useEffect, createContext } from 'react'
import { Container, Typography, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { THEME_COLORS } from '../constants'
import { Footer } from './Footer'
import { Header } from './Header'
import { MaxWidthContainer } from './MaxWidthContainer'
import { TabsSection } from './TabsSection'
import { PersonData } from '../peopleContent'

const { coconutMilk } = THEME_COLORS

export const PersonPageContext = createContext({
  selectedTabIndex: 0,
  setSelectedTabIndex: (v: number) => {},
})

interface PersonPageProps {
  section: PersonData
}

export function PersonPage({ section }: PersonPageProps) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const providerValue = useMemo(
    () => ({ selectedTabIndex, setSelectedTabIndex }),
    [selectedTabIndex]
  )
  useEffect(() => {
    document.querySelector('html').scrollTo({ top: 0 })
  }, [section, selectedTabIndex])

  return (
    <PersonPageContext.Provider value={providerValue}>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ height: '100%', minHeight: '100vh', backgroundColor: coconutMilk }}
      >
        <Container
          disableGutters
          maxWidth="md"
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MaxWidthContainer maxWidth="lg">
            <Header />
            <Content section={section} />
            <Footer />
          </MaxWidthContainer>
        </Container>
      </Container>
    </PersonPageContext.Provider>
  )
}

interface ContentProps {
  section: PersonData
  close?: () => void
  style?: Record<string, number | string>
}

export function Content({ section, close = undefined, style = {} }: ContentProps) {
  const isModal = Boolean(close)

  return (
    <Container
      maxWidth="xs"
      disableGutters
      sx={{
        minHeight: 'calc(100vh - 140px)',
        width: '90%',
        height: '95%',
        backgroundColor: coconutMilk,
        //borderRadius: 5,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...style,
      }}
    >
      <Container
        disableGutters
        sx={
          isModal
            ? {
                pt: '16px',
                pb: '17px',
                display: 'flex',
                justifyContent: 'center',
                position: 'sticky',
                top: '0px',
                backgroundColor: coconutMilk,
                zIndex: 1,
              }
            : {
                pt: { xs: '32px', sm: '48px' },
                pb: { xs: '25px', sm: '40px' },
                display: 'flex',
                justifyContent: 'center',
              }
        }
      >
        <Typography sx={{ fontSize: { xs: '16px', sm: '24px' }, fontWeight: 700 }}>
          {section.name}
        </Typography>
        {isModal && (
          <IconButton
            aria-label="close"
            color="primary"
            sx={{ position: 'absolute', right: 0, padding: '5px', pr: '0px' }}
            onClick={close ? () => close() : () => {}}
          >
            <CloseIcon sx={{ fontSize: '16px' }} />
          </IconButton>
        )}
      </Container>

      <TabsSection section={section} />
    </Container>
  )
}
