// @ts-nocheck
import { useState, useEffect, useRef, Fragment, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { peopleData } from '../peopleContent'
import { Content, PersonPageContext } from './PersonPage'
import { THEME_COLORS } from '../constants'
import pointAtPicture from '../assets/point-at-picture.svg'

const { coconutMilk } = THEME_COLORS
const modelsFolder = 'mind-ar'

const gems = {
  blue: {
    color: 'hsl(220, 80%, 100%)',
    emissive: 'hsl(200, 75%, 60%)',
  },
  yellow: {
    color: 'hsl(50, 80%, 100%)',
    emissive: 'hsl(40, 80%, 58%)',
  },
  orange: {
    color: 'hsl(30, 80%, 100%)',
    emissive: 'hsl(20, 82%, 56%)',
  },
  red: {
    color: 'hsl(10, 82%, 100%)',
    emissive: 'hsl(5, 82%, 54%)',
  },
}

AFRAME.registerComponent('gem-color', {
  init: function () {
    this.el.addEventListener('model-loaded', () => {
      const obj = this.el.getObject3D('mesh')
      const col = this.data

      obj.traverse((node) => {
        node.material.color.set(gems[col].color)
        node.material.emissive.set(gems[col].emissive)
        node.material.emissiveIntensity = 1.2
        node.material.alphaTest = 0.4
      })
    })
  },
})

export function ExperienceAR() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const providerValue = useMemo(
    () => ({ selectedTabIndex, setSelectedTabIndex }),
    [selectedTabIndex]
  )
  const sceneRef = useRef()
  const [backgroundTarget, setBackgroundTarget] = useState(null)
  const [currentTarget, setCurrentTarget] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [reloadCount, setReloadCount] = useState(0)

  const peopleId = peopleData.map(({ name, mindArIndex }) => {
    const personFirstName = name.split(' ')[0].toLowerCase()
    const idName = `${mindArIndex}-${personFirstName}`
    return idName
  })

  const getPersonColor = (i) => {
    if (i === 1) return 'orange'
    if (i === 0 || i === 3) return 'yellow'
    if (i === 2 || i === 4) return 'blue'
    if (i === 5 || i === 6 || i === 7) return 'red'
  }

  const foundPerson = peopleData.find((d) => d.mindArIndex === currentTarget)

  useEffect(() => {
    if (!sceneRef?.current?.renderer?.domElement) {
      return
    }

    const canvas = sceneRef.current.renderer.domElement

    // Reloads the window when the context is lost
    canvas.addEventListener(
      'webglcontextlost',
      function (event) {
        console.log('APP ERROR: Lost context: ', event)
        event.preventDefault()
        window.location.reload()
      },
      false
    )
  }, [sceneRef?.current, sceneRef?.current?.renderer])

  useEffect(() => {
    if (!sceneRef || !sceneRef.current) {
      return
    }

    const findTargetHandler = (event) => {
      const foundTargetIndex = Number(event.srcElement.id)
      if (!isModalOpen) {
        setBackgroundTarget(foundTargetIndex)
        setCurrentTarget(foundTargetIndex)
      }
    }
    const lostTargetHandler = (event) => {
      if (!isModalOpen) {
        setBackgroundTarget(null)
        setCurrentTarget(null)
      }
    }

    window.addEventListener('keypress', (event) => {
      console.log(event.key)
      if (Number.isFinite(Number(event.key))) {
        findTargetHandler({ srcElement: { id: Number(event.key) } })
        setIsModalOpen(true)
      }
    })

    sceneRef.current.addEventListener('targetFound', findTargetHandler)
    sceneRef.current.addEventListener('targetLost', lostTargetHandler)

    return () => {
      if (!sceneRef || !sceneRef.current) {
        return
      }
      sceneRef.current.removeEventListener('targetFound', findTargetHandler)
      sceneRef.current.removeEventListener('targetLost', lostTargetHandler)
      /* sceneRef.current.renderer.info.reset() */
    }
  }, [isModalOpen])

  /* console.log({isModalOpen, foundPerson, backgroundTarget, currentTarget}) */

  return (
    <PersonPageContext.Provider value={providerValue}>
      <Box sx={{ width: '100vw', height: '100vh', position: 'relative' }}>
        {backgroundTarget === null && <PointAtThePictureLabel />}

        <a-scene
          ref={sceneRef}
          mindar-image="imageTargetSrc: mind-ar/targets-with-margin.mind; maxTrack: 1; filterMinCF:0.0001; filterBeta: 0.001"
          color-space="sRGB"
          renderer="colorManagement: true, physicallyCorrectLights"
          vr-mode-ui="enabled: false"
          device-orientation-permission-ui="enabled: false"
        >
          <a-assets>
            <a-asset-item
              key={'param-gem'}
              id={'param-gem'}
              src={modelsFolder + '/param-gem.gltf'}
              response-type="arraybuffer"
              type="arraybuffer"
            />
            {peopleId.map((idName) => (
              <a-asset-item
                key={idName + '-shell'}
                id={idName + '-shell'}
                src={modelsFolder + '/' + idName + '-shell.gltf'}
                response-type="arraybuffer"
                type="arraybuffer"
              />
            ))}
          </a-assets>

          <a-camera
            position="0 0 0"
            look-controls="enabled: false"
            cursor="fuse: false; rayOrigin: mouse;"
            raycaster="far: 1000; objects: .clickable"
          />

          {peopleId.map((idName, i) => {
            return (
              <a-entity key={idName} id={i} mindar-image-target={`targetIndex: ${i}`}>
                <a-gltf-model
                  class="clickable"
                  rotation="0 0 0"
                  position="0 -0.22 0"
                  scale="0.2 0.2 0.2"
                  src={modelsFolder + '/param-gem.gltf'}
                  material=""
                  gem-color={getPersonColor(i)}
                  animation="property: rotation; to: 0 360 0; dur: 8000; easing: linear; loop: true;"
                  onClick={(event) => {
                    setIsModalOpen(true)
                  }}
                />
                <a-entity rotation="-25 40 10">
                  <a-gltf-model
                    class="clickable"
                    rotation="0 0 0"
                    position="0 0 0"
                    scale="2.6 2.6 2.6"
                    src={modelsFolder + '/' + idName + '-shell.gltf'}
                    animation="property: rotation; to: 0 -360 0; dur: 8000; easing: linear; loop: true;"
                    onClick={(event) => {
                      setIsModalOpen(true)
                    }}
                  />
                </a-entity>
              </a-entity>
            )
          })}
        </a-scene>

        {isModalOpen && foundPerson && (
          <Content
            section={foundPerson}
            close={() => {
              setIsModalOpen(false)
              setBackgroundTarget(null)
              setCurrentTarget(null)
              setSelectedTabIndex(0) // Reset, or it will open next person on the same tab
            }}
            style={{
              borderRadius: 5,
              position: 'absolute',
              top: '50px',
              bottom: '100px',
              height: '85%',
              left: 0,
              right: 0,
              zIndex: 3,
              padding: 3,
              paddingTop: 0,
              margin: 'auto',
              overflowY: 'auto',
              overflowX: 'hidden',
              WebkitOverflowScrolling: 'touch',
            }}
          />
        )}
      </Box>
    </PersonPageContext.Provider>
  )
}

function PointAtThePictureLabel() {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 100,
        backgroundColor: coconutMilk,
        padding: 2,
        borderRadius: 2,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '95%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={pointAtPicture}
        alt="How to point"
        style={{ height: 70, marginLeft: 15, marginRight: 10 }}
      />
      <Typography sx={{ fontSize: 21, fontWeight: 700, color: '#4D5B6A', textAlign: 'center' }}>
        Point at the picture to unlock the AR
      </Typography>
    </Box>
  )
}
