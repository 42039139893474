import { Breakpoint, Container } from '@mui/material'
import { ReactNode } from 'react'

interface MaxWidthContainerProps {
  maxWidth: Breakpoint | false
  children: ReactNode
}

export const MaxWidthContainer = ({ maxWidth, children }: MaxWidthContainerProps) => {
  return <Container maxWidth={maxWidth}>{children}</Container>
}
