import { Box, Container, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PersonData } from '../peopleContent'
import { THEME_COLORS } from '../constants'

const DEBUG = false
const { coconutMilk } = THEME_COLORS
interface HomepageCardsProps {
  persons: PersonData[]
}

function HomepageCard({ person }: { person: PersonData }) {
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false)
  const { name, color, url, image } = person

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        width: '100%',
        height: { xs: '100%', sm: '136px' },
        mb: '32px',
        cursor: 'pointer',
        borderRadius: 10,
        border: DEBUG ? '2px solid black' : 'none',
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onClick={() => navigate(`/wdwgfh/${url}`)}
    >
      <img
        width={250}
        height={136}
        src={image}
        alt={name}
        style={{
          objectFit: 'cover',
          objectPosition: '0 25%',
          minWidth: 250,
          borderRadius: 10,
          transform: `scale(${isHovered ? '1.1' : '1'})`,
          border: DEBUG ? '2px solid red' : 'none',
        }}
      />

      <Box
        sx={{
          flexGrow: 1,
          pl: { xs: '0 25%', sm: '28px' },
          border: DEBUG ? '2px solid green' : 'none',
        }}
      >
        <Typography variant="h4" color={color}>
          {name}
        </Typography>
      </Box>
    </Box>
  )
}

const HomepageCards = ({ persons }: HomepageCardsProps) => {
  return (
    <Box mt={'56px'} sx={{ backgroundColor: coconutMilk }}>
      <Typography variant="h2">EXPLORE THE STORIES OF</Typography>
      <Container disableGutters maxWidth="sm" sx={{ left: 0, marginTop: 3 }}>
        {persons.map((person) => {
          return <HomepageCard key={person.id} person={person} />
        })}
      </Container>
    </Box>
  )
}

export default HomepageCards
