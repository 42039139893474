import peopleDataRaw from './contents/persons.json'

export interface AudioContent {
  title: string
  transcript: string[]
  track: Track
}
export interface Track {
  title: string
  artist: string
  src: string
}

export type VideoContent = Video[]
export interface Video {
  title: string
  src: string
  transcript?: string[]
}

export interface PicturesContent {
  title: string
  imagesSrc: Picture[]
}
export interface Picture {
  url: string
  alt: string
  dimensions: {
    width: number
    height: number
  }
}

export type ChartsContent = Chart[]
export interface Chart {
  title: string
  ChartSvg: any
}

export interface PersonData {
  id: string
  mindArIndex: number
  url: string
  name: string
  color: string
  image?: string
  tabs: string[]
  audio?: AudioContent
  video?: VideoContent
  pictures?: PicturesContent
  charts?: ChartsContent
}

// TODO: remove ts-ignore
// @ts-ignore
export const peopleData: PersonData[] = peopleDataRaw
