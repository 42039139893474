export const THEME_COLORS = {
  coconutMilk: '#EFEDE6',
  darkGrey: '#414042',
  lightGray: '#c5c3bf',
  lightBlue: '#698B9B',
  orange: '#CF6E31',
  gold: '#A5833C',
  red: '#B55E4D',
  white: '#FFFFFF',
}
