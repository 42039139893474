import { SyntheticEvent, useContext } from 'react'
import { Tabs, Tab } from '@mui/material'
import { PersonPageContext } from './PersonPage'
import { VideoTab } from './VideoTab'
import { ChartsTab } from './ChartsTab'
import { PicturesTab } from './PicturesTab'
import { AudioTab } from './AudioTab'
import { PersonData } from '../peopleContent'

interface TabsSectionProp {
  section: PersonData
}
export function TabsSection({ section }: TabsSectionProp) {
  const { selectedTabIndex } = useContext(PersonPageContext)
  const tabsToShow = section.tabs

  return (
    <>
      <TabsNavigation tabsId={tabsToShow} />
      <TabContent section={section} tabId={tabsToShow[selectedTabIndex]} />
    </>
  )
}

interface TabContentProp {
  section: PersonData
  tabId: string
}
function TabContent({ section, tabId }: TabContentProp) {
  if (tabId === 'audio') {
    if (!section.audio) return null
    return <AudioTab key={section.id} audioTabData={section.audio} />
  }
  if (tabId === 'video') {
    if (!section.video) return null
    return <VideoTab videoTabData={section.video} />
  }
  if (tabId === 'pictures') {
    if (!section.pictures) return null
    return <PicturesTab picturesTabData={section.pictures} />
  }
  if (tabId === 'charts') {
    if (!section.charts) return null
    return <ChartsTab chartsTabData={section.charts} />
  }
  // eslint-disable-next-line no-console
  console.error(`Something goes wrong: "${tabId}" is not supported.`)
  return null
}

interface TabsNavigationProps {
  tabsId: string[]
}

function TabsNavigation({ tabsId }: TabsNavigationProps) {
  const { selectedTabIndex, setSelectedTabIndex } = useContext(PersonPageContext)

  return (
    <Tabs
      value={selectedTabIndex}
      onChange={(event: SyntheticEvent, tabIndex: number) => setSelectedTabIndex(tabIndex)}
      centered
      sx={{ width: '100%', marginY: 2, display: 'flex' }}
    >
      {tabsId.map((tab: string, i) => {
        return (
          <Tab
            sx={i !== 0 ? { ml: { xs: '32px', landing: '64px' } } : { ml: '0' }}
            key={tab}
            label={tab === 'pictures' ? 'PHOTOS' : tab.toUpperCase()} // sorry mate
            disableRipple
            disableTouchRipple
            disableFocusRipple
          />
        )
      })}
    </Tabs>
  )
}
