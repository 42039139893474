import { Box } from '@mui/material'
import { AudioContent } from '../peopleContent'
import { AudioPlayer } from './AudioPlayer'
import { TitleInTab } from './TitleInTab'
import { Transcript } from './Transcript'

interface Props {
  audioTabData: AudioContent
}

export function AudioTab({ audioTabData }: Props) {
  const { title, transcript, track } = audioTabData

  return (
    <Box sx={{ width: '100%' }}>
      <AudioPlayer tracks={[track]} />
      <TitleInTab text={title} />
      <Transcript paragraphs={transcript} />
    </Box>
  )
}
