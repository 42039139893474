import { useContext, useState } from 'react'
import { Button, Menu, MenuItem, ListItemText, List, ListItem } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate, useLocation } from 'react-router-dom'
import { peopleData, PersonData } from '../peopleContent'
import { PersonPageContext } from './PersonPage'
import { THEME_COLORS } from '../constants'
import useWindowSize from '../lib/useWindowSize'

const { orange, darkGrey } = THEME_COLORS

export function NavigationMenu() {
  const navigate = useNavigate()
  const location = useLocation()
  const { width } = useWindowSize()

  const { setSelectedTabIndex } = useContext(PersonPageContext)
  const [anchorElement, setAnchorElement] = useState(null)

  const isMenuOpen = Boolean(anchorElement)

  const openMenu = (event: any) => {
    setAnchorElement(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorElement(null)
  }

  function getMenuItem(section: PersonData) {
    const currentPath = location.pathname.substring(1)
    const isSelected = currentPath === section.url

    return (
      <MenuItem
        key={section.id}
        disableGutters
        onClick={() => {
          closeMenu()
          if (!isSelected) {
            setSelectedTabIndex(0)
            navigate(`/wdwgfh/${section.url}`)
          }
        }}
        sx={{
          color: isSelected ? orange : darkGrey,
        }}
      >
        {section.name}
      </MenuItem>
    )
  }

  return (
    <>
      <Button
        aria-label="menu"
        id="positioned-button"
        aria-controls={isMenuOpen ? 'positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={openMenu}
      >
        {width <= 650 ? <MenuIcon /> : 'MENU'}
      </Button>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorElement}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuSubsectionTitle text="Explore the Stories of" />
        {peopleData.map((s: PersonData) => getMenuItem(s))}
      </Menu>
    </>
  )
}

interface MenuSubsectionTitleProps {
  text: string
  sx?: Record<string, string | number>
  [x: string]: any
}
function MenuSubsectionTitle({ text, sx, ...rest }: MenuSubsectionTitleProps) {
  return (
    <List sx={{ padding: 0 }}>
      <ListItem sx={{ pt: 0, pb: '8px', pl: '32px', pr: '32px' }}>
        <ListItemText primary={text} />
      </ListItem>
    </List>
  )
}
