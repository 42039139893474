import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import './index.css'
import 'mind-ar/dist/mindar-image.prod.js'
import 'aframe'
import 'mind-ar/dist/mindar-image-aframe.prod.js'
import theme from './theme'
import reportWebVitals from './reportWebVitals'
import { Homepage } from './components/Homepage'
import { PersonPage } from './components/PersonPage'
import { HomepageAR } from './components/HomepageAR'
import { ExperienceAR } from './components/ExperienceAR'
import { peopleData } from './peopleContent'

document.addEventListener('DOMContentLoaded', init)

function init() {
  checkCookie()
  document.querySelector('#js-cooking-message-accept').addEventListener('click', () => {
    setCookie('PrivacyPolicy', 'true', 365)
    document.querySelector('.cookie-message').style.display = 'none'
  })
}
function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + 'true' + ';' + expires + ';path=/'
}

function getCookie(cname: string) {
  const name = cname + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function checkCookie() {
  const cookieExist = getCookie('PrivacyPolicy')
  if (cookieExist === '') {
    document.querySelector('#plcPrivacyPopup').style.display = 'block'
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/wdwgfh" replace />} />

          <Route path={'/wdwgfh'}>
            <Route path="ar" element={<HomepageAR />} />
            <Route path="ar-experience" element={<ExperienceAR />} />
            <Route path="" element={<Homepage />} />

            {peopleData.map((section) => (
              <Route
                key={section.id}
                path={section.url}
                element={<PersonPage section={section} />}
              />
            ))}

            <Route
              path="*"
              element={
                <main style={{ padding: '1rem' }}>
                  <p>Wrong url</p>
                </main>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
      <CssBaseline />
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
