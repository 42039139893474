import { Container, Typography } from '@mui/material'
import { Footer } from './Footer'
import landscape from '../assets/landscape.svg'
import { THEME_COLORS } from '../constants'
import HomepageCards from './HomepageCards'
import { MaxWidthContainer } from './MaxWidthContainer'
import { peopleData } from '../peopleContent'
import { useEffect } from 'react'
import settings from '../contents/settings.json'

const DEBUG = false
const { coconutMilk } = THEME_COLORS

export function Homepage() {
  useEffect(() => {
    document.querySelector('html').scrollTo({ top: 0 })
  }, [])
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        backgroundColor: coconutMilk,
        height: '100%',
        paddingTop: 5,
      }}
    >
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          border: `3px solid ${DEBUG ? 'red' : 'transparent'}`,
          backgroundImage: `url(${landscape})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
        }}
      >
        <Container
          disableGutters
          maxWidth="md"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            border: `3px solid ${DEBUG ? 'cyan' : 'transparent'}`,
          }}
        >
          <MaxWidthContainer maxWidth="lg">
            <Content />
            <Footer />
          </MaxWidthContainer>
        </Container>
      </Container>
    </Container>
  )
}

function Content() {
  return (
    <Container
      disableGutters
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        border: `3px solid ${DEBUG ? 'orange' : 'transparent'}`,
      }}
    >
      <Landing />
      <HomepageCards persons={peopleData} />
    </Container>
  )
}

function Landing() {
  return (
    <Container
      disableGutters
      sx={{
        paddingTop: '20%',
        border: `3px solid ${DEBUG ? 'teal' : 'transparent'}`,
        width: '100%',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
      }}
    >
      <Container
        disableGutters
        maxWidth="landing"
        sx={{
          marginRight: 1,
          marginTop: 1,
          marginBottom: 1,
          border: `3px solid ${DEBUG ? 'tomato' : 'transparent'}`,
          backgroundColor: 'rgba(239, 237, 230, 1)',
          marginLeft: 0,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: 30, sm: 44 },
            lineHeight: 1.2,
            letterSpacing: -1,
            paddingBottom: 1,
          }}
        >
          {settings.landingTitle}
        </Typography>
        <Typography variant="h2" sx={{ pb: 7 }}>
          {settings.landingSubtitle}
        </Typography>

        {settings.landingText.map((paragraph, i) => (
          <Typography key={i} sx={{ fontSize: 16 }}>
            {paragraph}
          </Typography>
        ))}
      </Container>
    </Container>
  )
}
