import { Gallery } from './Gallery'
import { TitleInTab } from './TitleInTab'
import { PicturesContent } from '../peopleContent'
interface Props {
  picturesTabData: PicturesContent
}

export function PicturesTab({ picturesTabData }: Props) {
  const { title, imagesSrc } = picturesTabData

  return (
    <>
      <Gallery images={imagesSrc} />
      <TitleInTab text={title} />
    </>
  )
}
