import { useEffect, useRef } from 'react'
import { THEME_COLORS } from '../constants'
import buildRegl from 'regl'

const { coconutMilk, lightBlue } = THEME_COLORS
const bg = fromHex(coconutMilk)
const fg = fromHex(lightBlue)
var AudioContext = window.AudioContext || window.webkitAudioContext

function fromHex(hex) {
  const rgb = [hex.slice(1, 3), hex.slice(3, 5), hex.slice(5, 7)]
  return rgb.map((c) => parseInt(c, 16))
}

function makeAnalyser(audioEl) {
  const context = new AudioContext()
  const analyser = context.createAnalyser()
  analyser.fftSize = 2048 // the default
  const media = audioEl._mediaElementSource || context.createMediaElementSource(audioEl)
  audioEl._mediaElementSource = media
  media.connect(analyser)
  media.connect(context.destination)

  return analyser
}

function makeFakeAnalyser() {
  return {
    _fake: true,
    // FAKE ANALYSER
    fftSize: 2048, // the default
    frequencyBinCount: 2048,
    getByteFrequencyData: (typedArray) => {
      typedArray.fill(0)
    },
    getByteTimeDomainData: (typedArray) => {
      typedArray.fill(128)
    },
  }
}

function instantiateAudioReactiveGlobe(container, audioEl) {
  const regl = buildRegl({
    extensions: ['OES_standard_derivatives'],
    container,
  })

  let analyser = makeFakeAnalyser()

  function onPlay() {
    // Chrome requires an AudioContext to be
    // created on user interaction.
    if (analyser._fake) analyser = makeAnalyser(audioEl)

    audioEl.removeEventListener('play', onPlay)
    audioEl.removeEventListener('click', onPlay)
  }

  audioEl.addEventListener('play', onPlay) // Chrome, Firefox
  audioEl.addEventListener('click', onPlay) // Safari fix because AudioContext can't be created in a 'play' handler

  container.addEventListener('click', () => {
    if (analyser._fake) onPlay()
    audioEl.paused ? audioEl.play() : audioEl.pause()
  })

  const fftSize = analyser.frequencyBinCount / 2 // check this division by 2
  const frequencies = new Uint8Array(fftSize)
  const frequenciesBuffer = regl.buffer({
    length: fftSize,
    type: 'uint8',
    usage: 'dynamic',
  })
  const amplitudes = new Uint8Array(fftSize)
  const amplitudesBuffer = regl.buffer({
    length: fftSize,
    type: 'uint8',
    usage: 'dynamic',
  })
  // prefill the non-0 array
  amplitudes.fill(128)
  amplitudesBuffer.subdata(amplitudes)

  const drawSpiral = regl({
    vert: `
      precision mediump float;
      #define FFT_SIZE ${fftSize}
      #define PI ${Math.PI}

      attribute float index;
      attribute float frequency;
      attribute float amplitude;

      uniform float t;
      uniform float c;
      uniform float a;
      uniform float f;

      mat4 rotX(float t) {
        return mat4(
          1.,  0.,     0.,      0.,
          0.,  cos(t), -sin(t), 0.,
          0.,  sin(t), cos(t),  0.,
          0.,  0.,     0.,      1.
        );
      }

      mat4 rotY(float t) {
        return mat4(
          cos(t),  0.,   sin(t),  0.,
          0.,      1.,   0.,      0.,
          -sin(t), 0.,   cos(t),  0.,
          0.,      0.,   0.,      1.
        );
      }

      mat4 rotZ(float t){
        return mat4(
          cos(t),  -sin(t),  0.,  0.,
          sin(t),   cos(t),  0.,  0.,
          0.,      0.,       1.,  0.,
          0.,      0.,       0.,  1.
        );
      }

      void main() {
        float theta = PI * index / float(FFT_SIZE);
        float whiteNoiseFilter =  .5 + smoothstep(0., 50., index) * 0.5;
        float distortion = c + (f * frequency) * whiteNoiseFilter + a * amplitude;

        gl_Position = vec4(
          0.3 * sin(theta) * distortion * sin(theta * 40.0),
          0.3 * cos(theta) * distortion,
          0.3 * sin(theta) * distortion * cos(theta * 40.0),
          1
        ) * rotX(0.5) * rotY(t);
      }
    `,

    frag: `
      void main() {
        gl_FragColor = vec4(${fg.map((c) => (c / 255).toFixed(2)).join(', ')}, 1);
      }
    `,

    attributes: {
      index: Array(fftSize)
        .fill()
        .map((_, i) => i),
      frequency: {
        buffer: frequenciesBuffer,
        normalized: true,
      },
      amplitude: {
        buffer: amplitudesBuffer,
        normalized: true,
      },
    },
    uniforms: {
      t: ({ tick }) => 0.01 * tick,
      a: regl.prop('a'),
      f: regl.prop('f'),
      c: regl.prop('c'),
    },
    elements: null,
    instances: -1,
    lineWidth: 1,
    depth: { enable: true },
    count: fftSize,
    primitive: 'line strip',
  })

  const loop = regl.frame(({ tick }) => {
    try {
      // Clear draw buffer
      regl.clear({
        color: [...bg.map((c) => c / 255), 1],
        depth: 1,
      })

      if (!audioEl.paused) {
        // Poll audio data
        analyser.getByteFrequencyData(frequencies)
        analyser.getByteTimeDomainData(amplitudes)
        // Update the buffer in place
        frequenciesBuffer.subdata(frequencies)
        amplitudesBuffer.subdata(amplitudes)
      }

      // Draw the spectrum
      drawSpiral({ a: 1, f: 0, c: 1.5 })
      drawSpiral({ a: 0, f: 1, c: 2 })
    } catch (err) {
      loop.cancel()
      console.error(err)
    }
  })
}

export function AudioReactiveGlobe({ audioFile }) {
  const divRef = useRef()
  useEffect(() => {
    const container = divRef.current
    if (!container) return
    instantiateAudioReactiveGlobe(container, audioFile)

    return () => {
      container.removeChild(container.querySelector('canvas'))
    }
  }, [])

  return (
    <div id="wrapper-audio-globe" style={{ position: 'relative', paddingBottom: '100%' }}>
      <div
        id="audio-globe"
        ref={divRef}
        style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
      />
    </div>
  )
}
