import IconButton from '@mui/material/IconButton'
import { ReactNode } from 'react'

interface AudioPlayerButtonProps {
  ariaLabel: string
  onClick: () => void
  icon: ReactNode
  disabled?: boolean
}

const AudioPlayerButton = ({
  onClick,
  ariaLabel,
  icon,
  disabled = false,
}: AudioPlayerButtonProps) => {
  return (
    <IconButton
      className="border-none"
      aria-label={ariaLabel}
      onClick={disabled ? undefined : onClick}
      disableFocusRipple={true}
      color="primary"
      style={{
        outline: 'none',
        opacity: disabled ? 0.5 : 1,
      }}
    >
      {icon}
    </IconButton>
  )
}

export default AudioPlayerButton
