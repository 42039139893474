import { Box } from '@mui/material'
import { TitleInTab } from './TitleInTab'
import { ChartsContent } from '../peopleContent'

interface Props {
  chartsTabData: ChartsContent
}

export function ChartsTab({ chartsTabData }: Props) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {chartsTabData.map(({ title, ChartSvg }, i) => {
        return (
          <Box key={i}>
            <TitleInTab text={title} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <img src={ChartSvg} alt={title} />
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
